import React, { Component } from 'react';
import iconSet from '../../site_icons/selection.json'
import IcomoonReact, { iconList } from 'icomoon-react'
import AniLink from "gatsby-plugin-transition-link/AniLink"

import SiteContext from "../context/SiteContext"

class IconLinkCard extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <SiteContext.Consumer>
                {siteContext => (
                    <AniLink swipe duration={.2} direction="left" top="entry" to={this.props.url} className="icon-link-card">
                        <div className="icon-link-card__icon">
                            <IcomoonReact iconSet={iconSet} size={35} icon={this.props.icon} />
                        </div>
                        <div className="icon-link-card__title">
                            {this.props.title}
                        </div>
                        <div className="icon-link-card__arrow">
                            <IcomoonReact iconSet={iconSet} size={25} icon="arrow-right" />
                        </div>
                    </AniLink>
                )}
            </SiteContext.Consumer>
        );
    }
}

export default IconLinkCard;
